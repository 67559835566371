import React from 'react';
import io from 'socket.io-client';
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import {Provider} from 'mobx-react';
import { Unity, useUnityContext } from "react-unity-webgl";

const socketHost = process.env.SOCKET_HOST ? process.env.SOCKET_HOST : '';
//const socket = io(socketHost);
const theme = createMuiTheme({
    palette: {
        primary: {
            main: purple[500],
        },
        secondary: {
            main: green[500],
        },
    },
});
const store = {gameEngine:null};



export default () => {

    const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
            loaderUrl: "Build/build.loader.js",
            dataUrl: "Build/build.data.gz",
            frameworkUrl: "Build/build.framework.js.gz",
            codeUrl: "Build/build.wasm.gz",
        });

        return (
            <>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <div className="App">
                        <Provider {...store} store={store}>

                            <div style={{width: '900px', height: '600px'}}>
                                {!isLoaded && (
                                    <p>Loading Application... {Math.round(loadingProgression * 100)}%</p>
                                )}
                            <Unity unityProvider={unityProvider}
                                   style={{
                                       width: '100%',
                                       height: '100%',
                                       visibility: isLoaded ? "visible" : "hidden"
                                    }}
                            />
                            </div>
                        </Provider>
                    </div>
                </ThemeProvider>
            </>
        );
}
